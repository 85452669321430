import { Collapse, Upload } from "antd";
import React, { useState } from "react";
import flash from "../icons/flash.svg";
import star from "../icons/star.svg";
import user from "../icons/user.svg";
import eye from "../icons/eye.svg";
import download from "../icons/download.svg";
import close from "../icons/close.svg";
import attachment from "../icons/attachment.svg";
import calendar from "../icons/calendar.png";
const Panel = Collapse.Panel;
const Documents = ({ title, subTitle }) => {
  const [fileList, setFileList] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState(false);
  console.log(fileList);
  return (
    <Collapse
      className="documents"
      onChange={(e) => {
        setCollapseOpen(e.length > 0);
      }}
    >
      <Panel
        showArrow={false}
        header={
          <div className="header">
            <div className="head-details">
              <h3>
                {title}
                {!collapseOpen && subTitle ? (
                  <span className="dim-text">({subTitle})</span>
                ) : null}
              </h3>
              {collapseOpen ? (
                <>
                  <p>
                    <img src={user} width={10} />
                    Last Updated by : Customer (Abhishek Verma)
                  </p>
                  <p>
                    <img src={calendar} width={10} />
                    Created: 20 Mar, 2023 at 10:10
                  </p>
                </>
              ) : null}
            </div>
            <div className="status">
              <span>
                <img src={flash} width={13} />
              </span>
              Auto Verified
              <img src={star} width={15} className="star" />
            </div>
          </div>
        }
      >
        <div className="content">
          <Upload
            className="upload-button"
            fileList={[]}
            onChange={(e) => {
              setFileList((fList) => [...fList, ...e.fileList]);
            }}
          >
            <button>
              Attach File <img src={attachment} width={15} />
            </button>
          </Upload>
          {fileList?.map((ele) => {
            return (
              <div className="view">
                <div className="file-details">
                  <img src={URL.createObjectURL(ele.originFileObj)} />
                  <span>{ele.name}</span>
                </div>
                <div className="actions">
                  <button>
                    <img src={download} width={20} />
                  </button>
                  <button>
                    <img src={eye} width={20} />
                  </button>
                  <button>
                    <img src={close} width={20} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </Panel>
    </Collapse>
  );
};

export default Documents;
