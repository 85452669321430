import React, { useEffect } from "react";
import { amountFormat } from "../../../utils";
var Highcharts = require("highcharts");

const ExpenditureChart = ({ showAmt }) => {
  const data = [
    { month: "Jan", value: 1122.83 },
    { month: "Feb", value: 11122.83 },
    { month: "Mar", value: 21122.83 },
    { month: "Apr", value: 51122.83 },
    { month: "May", value: 21122.83 },
    { month: "Jun", value: 5122.83 },
    { month: "Jul", value: 1122.83 },
    { month: "Aug", value: 201122.83 },
    { month: "Sep", value: 21122.83 },
    { month: "Oct", value: 11122.83 },
    { month: "Nov", value: 20012.83 },
    { month: "Dec", value: 10122.83 },
  ];
  useEffect(() => {
    Highcharts.chart("container", {
      colors: ["#11264A", "#EC7B64"],
      chart: {
        type: "column",
      },
      title: {
        text: " ",
      },
      xAxis: {
        categories: [...data.map((ele) => ele.month)],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        tickInterval: 50000,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      tooltip: {
        formatter: function () {
          return (
            "<b>" +
            this.series.name +
            `</b><br/>` +
            `Rs. ` +
            amountFormat(this.point.y)
          );
        },
      },
      series: [
        {
          name: "Income",
          data: data?.map(() => 120000),
        },
        {
          name: "Expenditure",
          data: data?.map((ele) => parseInt(ele.value)),
        },
      ],
    });
  }, []);

  return (
    <div
      className="expenditure-chart"
      id="container"
      style={{ width: "100%", height: 250 }}
    ></div>
  );
};

export default ExpenditureChart;
