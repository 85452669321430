import React, { useEffect } from "react";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Common/TableComp/TableHeader";
import { Col, Row } from "antd";
import { amountFormat } from "../../utils";
import { headerTitles } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Loans = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Loans",
        description: "",
      })
    );
  }, []);
  const loanApplications = [
    {
      caseId: 1,
      applicationName: "ABC Loan",
      source: "Online",
      createdBy: "John Doe",
      loanAmount: 50000,
      stage: "Review",
      status: "Open",
    },
    {
      caseId: 2,
      applicationName: "XYZ Financing",
      source: "Branch",
      createdBy: "Jane Smith",
      loanAmount: 75000,
      stage: "Approved",
      status: "Closed",
    },
    {
      caseId: 3,
      applicationName: "Startup Loan",
      source: "Online",
      createdBy: "Mike Johnson",
      loanAmount: 100000,
      stage: "Funded",
      status: "Closed",
    },
    {
      caseId: 4,
      applicationName: "Mortgage",
      source: "Branch",
      createdBy: "Sarah Lee",
      loanAmount: 200000,
      stage: "Review",
      status: "Open",
    },
    {
      caseId: 5,
      applicationName: "Small Business",
      source: "Online",
      createdBy: "David Wong",
      loanAmount: 30000,
      stage: "Approved",
      status: "Closed",
    },
  ];

  const columns = [
    {
      title: "Case ID",
      dataIndex: "caseId",
      key: "caseId",
    },
    {
      title: "Application Name",
      dataIndex: "applicationName",
      key: "applicationName",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      key: "loanAmount",
      render: (amt) => "Rs. " + amountFormat(amt),
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div>
          {status.includes("Open") && (
            <span style={{ color: "orange" }}>Open</span>
          )}
          {status.includes("Closed") && (
            <span style={{ color: "green" }}>Closed</span>
          )}
        </div>
      ),
    },
  ];

  return (
    <Row wrap={false} className="table-wrapper mt2">
      <Col flex="auto" className="table-wrapper-col">
        <div className="flex-grow">
          <TableHeader />
          <div style={{ paddingTop: "15px" }}>
            <TableComponent
              columns={columns}
              hasCheckbox={false}
              data={loanApplications}
              handleRowClick={() => {
                history.push("/admin/loans/details");
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Loans;
