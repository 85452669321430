import { Col, Row } from "antd";
import React from "react";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Common/TableComp/TableHeader";

const SanctionConditions = () => {
  
  
  const columns = [
    {
      title: 'S No',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Conditions',
      dataIndex: 'conditions',
      key: 'conditions',
    },
    {
      title: 'Raised By',
      dataIndex: 'raisedBy',
      key: 'raisedBy',
    },
    {
      title: 'Raised Date',
      dataIndex: 'raisedDate',
      key: 'raisedDate',
    },
    {
      title: 'Resolved date',
      dataIndex: 'resolvedDate',
      key: 'resolvedDate',
    },
    {
      title: 'Resolved by',
      dataIndex: 'resolvedBy',
      key: 'resolvedBy',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`status status_${status === 'Open' ? 'pending' : 'closed'}`}>
          {status}
        </span>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      sno: 1,
      conditions: 'Submit Office land documents',
      raisedBy: 'Saurabh K',
      raisedDate: '23 July 23',
      resolvedDate: '-',
      resolvedBy: '-',
      status: 'Open',
    },
    {
      key: '2',
      sno: 2,
      conditions: 'Latest 12 months bank statement ne ...',
      raisedBy: 'Saurabh K',
      raisedDate: '19 July 23',
      resolvedDate: '-',
      resolvedBy: '-',
      status: 'Open',
    },
    {
      key: '3',
      sno: 3,
      conditions: 'Collateral NOC to be collected',
      raisedBy: 'Saurabh K',
      raisedDate: '22 June 23',
      resolvedDate: '2 July 23',
      resolvedBy: 'Viyaan S',
      status: 'Closed',
    },
  ];


  return (
    <Row wrap={false} className="table-wrapper m2">
      <Col flex="auto" className="table-wrapper-col">
        <div className="flex-grow">
          <TableHeader noDD={true} />
          <div style={{ paddingTop: "15px" }}>
            <TableComponent columns={columns} hasCheckbox={false} data={data} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SanctionConditions;
