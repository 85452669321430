import { ReducerFunctions } from "../../utils";
import {
  Employees,
  AddEmployees,
  DeleteEmployees,
  EmployeesStatus,
  UsersList,
  UsersListExport,
  ClientListExport,
  StaffUsersList,
  AddStaffUser,
  StaffUser,
  StaffUserDelete,
} from "./employees";

export const employees_reducer = ReducerFunctions(Employees, {});
export const employee_reducer = ReducerFunctions(StaffUser, {});
export const employee_delete_reducer = ReducerFunctions(StaffUserDelete, {});
export const employees_status_reducer = ReducerFunctions(EmployeesStatus, {});
export const add_employees_reducer = ReducerFunctions(AddEmployees, {});
export const delete_employees_reducer = ReducerFunctions(DeleteEmployees, {});
export const users_list_reducer = ReducerFunctions(UsersList, {});
export const staff_users_list_reducer = ReducerFunctions(StaffUsersList, {});
export const add_staff_user_reducer = ReducerFunctions(AddStaffUser, {});
export const users_list_export_reducer = ReducerFunctions(UsersListExport, {});
export const client_list_export_reducer = ReducerFunctions(ClientListExport,{});
