import React from "react";
import email from "../../../assets/icons/email.png";
import phone from "../../../assets/icons/phone.png";
import tel from "../../../assets/icons/tel.png";
import user from "../../../assets/icons/user_icon.png";

import { Col, Row } from "antd";
import { amountFormat } from "../../../utils";
const Profile = () => {
  
  return (
    <div>
      <div className="pt2 loan-sec profile-sec">
        <div className="applicant-details">
          <h3>Bussiness Information</h3>
          <div className="details">
            <div className="item-detail">
              <label>Application ID</label>
              <p>#UNIWC-342</p>
            </div>
            <div className="item-detail">
              <label>Organisation Name</label>
              <p>IBL Steel Limited</p>
            </div>
            <div className="item-detail">
              <label>Organisation PAN</label>
              <p>AALCP5523Y</p>
            </div>
            <div className="item-detail">
              <label>Certificate of Incorporation</label>
              <p>23423498H897GD837</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>GST Treatment</label>
              <p>#Registered Business - Regular</p>
            </div>
            <div className="item-detail">
              <label>GSTIN</label>
              <p>36AALCP5523Y1Z8</p>
            </div>
            <div className="item-detail">
              <label>Office Location</label>
              <p>Hyderabad</p>
            </div>
            <div className="item-detail">
              <label>Last Filing Date</label>
              <p>July 23</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Incorporation Date</label>
              <p>29-10-2012</p>
            </div>
            <div className="item-detail">
              <label>Paid-Up Capital</label>
              <p>
                Rs.  {amountFormat(100000)}
              </p>
            </div>
            <div className="item-detail">
              <label>Authorised Capital</label>
              <p>
                Rs.  {amountFormat(100000)}
              </p>
            </div>
            <div className="item-detail">
              <label>Shareholders/Directors</label>
              <p>3</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Latest year turn over</label>
              <p>10-15 Cr</p>
            </div>
            <div className="item-detail">
              <label>Office Type</label>
              <p>Rented</p>
            </div>
            <div className="item-detail">
              <label>No of employees</label>
              <p>124</p>
            </div>
            <div className="item-detail"></div>
          </div>
          <div className="line" />
          <div>
            <h3>Business Address Details</h3>
            <div className="other-info">
              <Row className="flex-wrap">
                <Col span={10}>
                  <p>
                    <label>{"Office Address"}</label>
                  </p>

                  <p className="mb1">
                    Jeevan Building, Street 4, Kakaityaa Hills, Hitech city
                    <br />
                    Hyderabad,
                    <br />
                    Telegana
                    <br />
                    500044
                    <br />
                    India
                    <br />
                    Phone: 040-23232390
                  </p>
                </Col>
                <Col span={10}>
                  <p>
                    <label>{"Registered Address"}</label>
                  </p>
                  <p className="mb1">
                    Jeevan Building, Street 4, Kakaityaa Hills, Hitech city
                    <br />
                    Hyderabad,
                    <br />
                    Telegana
                    <br />
                    500044
                    <br />
                    India
                    <br />
                    Phone: 040-23232390
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div className="line" />
          <div className="contact-details">
            <h3>{"Contact Details"}</h3>
            <div className="other-info">
              <Row className="flex-wrap">
                <Col span={10}>
                  <div className="contact">
                    <img src={user} alt="" className="user-img" />
                    <div>
                      <p>Mr. Lokesh Ankam</p>
                      <p className="dim">
                        <img src={email} width={16} /> lokeshankam@gmail.com
                      </p>
                      <div className="flex-between gap2">
                        <p className="dim">
                          <img src={tel} width={14} /> 040-232323290
                        </p>
                        <p className="dim">
                          <img src={phone} width={10} /> +91 7502323232
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="line mt5" />
        </div>
      </div>
      <div className="pt2 loan-sec director-details">
        <div className="applicant-details">
          <h3>Director Details</h3>
          <div className="promoter-details">
            <div className="name-details">
              <div>
                <img
                  src={user}
                  alt="avatar"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <p>
                  <b>Abhishek Verma</b>
                </p>
                <p className="acc-stat">Last synced at 10:45</p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>Preffered Language</label>
                <p>English</p>
              </div>
              <div className="item-detail">
                <label>Last Contact</label>
                <p>4 days</p>
              </div>
              <div className="item-detail">
                <label>Customer Since</label>
                <p>3 years</p>
              </div>
              <div className="item-detail">
                <label>Gender</label>
                <p>Male</p>
              </div>
            </div>
          </div>
          <div className="line" />
          <div>
            <div className="details">
              <div className="item-detail">
                <label>First Name</label>
                <p>Abhishek</p>
              </div>
              <div className="item-detail">
                <label>Middle Name</label>
                <p>-</p>
              </div>
              <div className="item-detail">
                <label>Last Name</label>
                <p>Verma</p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>PAN Number</label>
                <p>FKLPS8560P</p>
              </div>
              <div className="item-detail">
                <label>Aadhar No.</label>
                <p>326590296825</p>
              </div>
              <div className="item-detail"></div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>DOB</label>
                <p>24-09-1985</p>
              </div>
              <div className="item-detail">
                <label>Martial Status</label>
                <p>Single</p>
              </div>
              <div className="item-detail"></div>
            </div>
          </div>
        </div>
        <div className="loan-status">
          <div className="other-info mt3">
            <div className="mb2">
              <div className="contact">
                <div>
                  <p className="dim">
                    <img src={email} width={16} /> lokeshankam@gmail.com
                  </p>
                  <div className="flex-between gap2">
                    <p className="dim">
                      <img src={tel} width={14} /> 040-232323290
                    </p>
                    <p className="dim">
                      <img src={phone} width={10} /> +91 7502323232
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb2">
              <p>
                <label>{"Permanent Address"}</label>
              </p>

              <p className="mb1">
                Jeevan Building, Street 4, Kakaityaa Hills, Hitech city
                <br />
                Hyderabad,
                <br />
                Telegana
                <br />
                500044
                <br />
                India
                <br />
                Phone: 040-23232390
              </p>
            </div>
            <div className="mb2">
              <p>
                <label>{"Residence Address"}</label>
              </p>
              <p className="mb1">
                Jeevan Building, Street 4, Kakaityaa Hills, Hitech city
                <br />
                Hyderabad,
                <br />
                Telegana
                <br />
                500044
                <br />
                India
                <br />
                Phone: 040-23232390
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
