import React, { useState } from "react";
import CamInputs from "./CAMSummary/CamInputs";
import Eligibilty from "./CAMSummary/Eligibilty";
import SanctionConditions from "./CAMSummary/SanctionConditions";
import Comments from "./Comments";
import Logs from "./Logs";
const CAMSummary = () => {
  const [tab1, setTab1] = useState("1");
  const items1 = [
    {
      key: "1",
      label: "Eligibilty",
    },
    {
      key: "2",
      label: "CAM Inputs",
    },
    {
      key: "3",
      label: "Sanction Conditions",
    },
    {
      key: "4",
      label: "Memo",
    },
    {
      key: "5",
      label: "Comments",
    },
    {
      key: "6",
      label: "Event logs",
    },
  ];

  return (
    <>
      <div className="sub-tabs">
        <div className="tabs-sec">
          <ul>
            {items1.map(
              (ele) =>
                ele?.label && (
                  <li
                    onClick={() => setTab1(ele.key?.toLowerCase())}
                    className={`${
                      tab1.toLowerCase() === ele.key?.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                  >
                    {ele.label}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {tab1 === "1" && <Eligibilty />}
      {tab1 === "2" && <CamInputs />}
      {tab1 === "3" && <SanctionConditions />}
      {tab1 === "5" && <Comments />}
      {tab1 === "6" && <Logs />}
    </>
  );
};

export default CAMSummary;
