import React, { useState } from "react";
import down from "../icons/caret-down.svg";
import { Collapse, Radio, Upload } from "antd";
import axios from "axios";
const Panel = Collapse.Panel;

const RCUCheck = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [locData, setLocData] = useState(null);
  const [location, setLocation] = useState({});

  return (
    <Collapse
      className="documents"
      onChange={(e) => {
        setCollapseOpen((b) => !b);
      }}
    >
      <Panel
        showArrow={false}
        header={
          <div className="header">
            <div className="head-details">
              <h3>
                Residence Address
                <span className="dim-text">(Location of promoters stay)</span>
              </h3>
            </div>
            <div className={collapseOpen ? "rotate smooth" : "smooth"}>
              <img src={down} width={10} />
            </div>
          </div>
        }
      >
        <div className="content rcu">
          <div className="address-sec">
            <div className="details">
              <div className="item-detail">
                <label>House & Flat No</label>
                <p>Flat no 601</p>
              </div>
              <div className="item-detail">
                <label>Address Line 1</label>
                <p>Jeevan Building</p>
              </div>
              <div className="item-detail">
                <label>Address Line 2</label>
                <p>Street 4, Kakaityaa Hills, Hitech city</p>
              </div>
              <div className="item-detail">
                <label>District</label>
                <p>Rangareddy</p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>City</label>
                <p>Hyderabad</p>
              </div>
              <div className="item-detail">
                <label>Pin Code</label>
                <p>500033</p>
              </div>
              <div className="item-detail">
                <label>Landmark</label>
                <p>Opp Kakaityaa Hills Society Building</p>
              </div>
              <div className="item-detail">
                <label>Contact No</label>
                <p>+91 84534 53544</p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>No of years at current address</label>
                <p>2+</p>
              </div>
              <div className="item-detail">
                <label>Residence type</label>
                <p>Owned</p>
              </div>
              <div className="item-detail">
                <label>No of months in current city</label>
                <p>48</p>
              </div>
              <div className="item-detail"></div>
            </div>
          </div>
          <div className="mt2">
            <h3>Geo-Location Images</h3>
            <div className="geo-wrap">
              {fileList?.map((ele) => {
                return (
                  <div className="geo-img">
                    <img src={URL.createObjectURL(ele.originFileObj)} />
                  </div>
                );
              })}
              <Upload
                className="geo-button"
                fileList={[]}
                onChange={(e) => {
                  setFileList((fList) => [...fList, ...e.fileList]);
                }}
              >
                <button className="geo-loc-add">Add New +</button>
              </Upload>
            </div>
            <div className="line" />
            <h3>Capture Current Geo-Location</h3>
            <div className="geo-loc-wrap mb2">
              <button
                className="geo-location-add"
                onClick={() => {
                  if (navigator.geolocation)
                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        axios
                          .get(
                            "https://api.ipgeolocation.io/ipgeo?apiKey=2554a8aa72164d0da15010f04311b421"
                          )
                          .then((res) => {
                            setLocData(res?.data);
                          });
                        setLocation({
                          latitude: position.coords.latitude,
                          longitude: position.coords.longitude,
                        });
                      },
                      (error) => {
                        console.error("Error getting location:", error);
                      }
                    );
                }}
              >
                Capture Location +
              </button>
              <div className="addr-wrap">
                {locData && (
                  <div className="address">
                    <img src={locData?.country_flag} />
                    <span>City: {locData?.city}</span>
                    <span>State: {locData?.state_prov}</span>
                    <span>Country: {locData?.country_name}</span>
                    <span>Latitude: {locData?.latitude}</span>
                    <span>Longitude: {locData?.longitude}</span>
                  </div>
                )}
                <p>Click the button to capture geolocation of your device</p>
                <p>Ensure you have allowed GPS Permissions</p>
              </div>
            </div>
            <div className="line" />
            <h3>Verification & Check List</h3>
            <div className="address-sec">
              <div className="radio-check">
                <label className="title">Neighbour Check</label>
                <div>
                  <Radio name="neighbour">Yes</Radio>
                  <Radio name="neighbour">No</Radio>
                </div>
              </div>
              <div className="radio-check">
                <label className="title">Original documents Seen</label>
                <div>
                  <Radio name="neighbour">Yes</Radio>
                  <Radio name="neighbour">No</Radio>
                </div>
              </div>
              <div className="radio-check">
                <label className="title">Name Plate Visible</label>
                <div>
                  <Radio name="neighbour">Yes</Radio>
                  <Radio name="neighbour">No</Radio>
                </div>
              </div>
              <div className="radio-check">
                <label className="title">Community Dominated</label>
                <div>
                  <Radio name="neighbour">Yes</Radio>
                  <Radio name="neighbour">No</Radio>
                </div>
              </div>
              <div className="radio-check">
                <label className="title">Same Address as Mentioned</label>
                <div>
                  <Radio name="neighbour">Yes</Radio>
                  <Radio name="neighbour">No</Radio>
                </div>
              </div>
              <div className="details mt2">
                <div className="item-detail">
                  <label>Distance from nearest branch</label>
                  <p>3 Kms</p>
                </div>
                <div className="item-detail">
                  <label>Family Members</label>
                  <p>4</p>
                </div>
                <div className="item-detail">
                  <label>No of Earning Members</label>
                  <p>1</p>
                </div>
                <div className="item-detail">
                  <label>Overall Family income</label>
                  <p>Rs. 2-4 lakhs</p>
                </div>
              </div>
              <div className="details">
                <div className="item-detail">
                  <label>No of Storeys</label>
                  <p>30</p>
                </div>
                <div className="item-detail">
                  <label>Occupied Since</label>
                  <p>2014</p>
                </div>
                <div className="item-detail">
                  <label>Landmark</label>
                  <p>Opp Kakaityaa Hills Society Building</p>
                </div>
                <div className="item-detail"></div>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default RCUCheck;
