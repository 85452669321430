import React, { useState } from "react";
import LeftArrow from "../../assets/icons/arrow-left.svg";
import PasswordIcon from "../../assets/icons/password.svg";
import { RouteConstants } from "../../utils";
import { Link } from "react-router-dom";
import { Button, Form, Typography, Input, message } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import endpoint from "../../config/Axios";
import { apis } from "../../config/APIs";

const { Text, Title } = Typography;

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Enter valid email").required("Email is required."),
});
const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setLoading(true)
      endpoint.post(apis.resetPassword + `?email=${values.email}`).then(res => {
        message.success("Reset password link has been sent to your email.")
        setLoading(false)
      }).catch(err => {
        message.error("Failed to send email.")
        setLoading(false)

      })
    },
  });
  return (
    <div className="reset-form">
      <div className="head-sec">
        <div className="icon-background">
          <img
            src={PasswordIcon}
            alt={"PasswordIcon"}
            className="passwordIcon"
          />
        </div>
        <Title level={3} className="mt2">
          Reset your password
        </Title>
        <Text className="text-dim">
          We will send you an email with a link to reset your password
        </Text>
        {/* <Text className="text-dim">Check your inbox, we just sent you an email at Jeevan.kumar@avm.com</Text> */}
      </div>
      <Form onFinish={formik.handleSubmit}
        layout="vertical"
        className="loginFormDiv">
        <Form.Item
          label={"Email Address"}
        >
          <Input
            placeholder="Enter Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error">{formik.errors.email}</div>
          )}
        </Form.Item>
        <div className="mt2">
          <Button type="primary" htmlType="submit" className="loginButton" disabled={loading}>
            Reset Password
          </Button>
        </div>
      </Form>
      <div className="mt2 mb2">
        <Link to={RouteConstants.ROOT}>
          <span className="text-dim cursor">
            <img src={LeftArrow} alt={"LeftArrow"} className="leftArrow mr1" />
            Back to Login
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
