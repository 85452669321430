import { Collapse } from "antd";
import React from "react";
import { amountFormat } from "../../../utils";

import calendar from "../icons/calendar.png";
import user from "../icons/user.svg";
import IncomeChart from "./IncomeChart";
import ExpenditureChart from "./ExpenditureChart";
import DTIChart from "./DTIChart";
const Panel = Collapse.Panel;
const CreditAnalysis = () => {
  return (
    <div className="credit-analysis">
      <Collapse className="documents">
        <Panel
          showArrow={false}
          header={
            <div className="header">
              <div className="head-details">
                <h3>
                  Credit risk review
                  <span className="status status_pending ml1">
                    Under Review
                  </span>
                </h3>

                <p>
                  <img src={user} width={10} />
                  Last Updated by : Customer (Abhishek Verma)
                </p>
                <p>
                  <img src={calendar} width={10} />
                  Created: 20 Mar, 2023 at 10:10
                </p>
              </div>
            </div>
          }
        >
          <div className="content">
            <div className="risk-score">
              <h3>Final risk score</h3>
              <p className="d-flex align-center">
                780 <span className="ml1 risk_status">Good</span>
              </p>
            </div>
            <div className="overdue-note">
              <p className="title">Overdue repayments require attention</p>
              <p className="sub-title">
                Manual credit risk review is required due to multiple overdue
                repayments in the last 12months
              </p>
              <button>Review Repayment History </button>
            </div>
            <div className="mt2">
              <h3>Working Capital Loan</h3>
              <div className="working-capital">
                <div>
                  <div className="prod-img"></div>
                </div>
                <div>
                  <div className="details">
                    <div className="item-detail">
                      <label>Automated review</label>
                      <p>
                        <span className="risk_status">Approved</span>
                      </p>
                    </div>
                    <div className="item-detail double">
                      <label>Selected vehicle</label>
                      <p>2022 Harley Davidson, LiveWire ONE</p>
                    </div>
                  </div>
                  <div className="details">
                    <div className="item-detail">
                      <label>APR</label>
                      <p>6.8%</p>
                    </div>
                    <div className="item-detail">
                      <label>Monthly payment</label>
                      <p>
                        Rs.  {amountFormat(203902)}
                      </p>
                    </div>
                    <div className="item-detail">
                      <label>Duration</label>
                      <p>36 months</p>
                    </div>
                  </div>
                  <div className="details">
                    <div className="item-detail">
                      <label>Loan amount</label>
                      <p>
                        Rs.  {amountFormat(7200000)}
                      </p>
                    </div>
                    <div className="item-detail">
                      <label>Total amount repayable</label>
                      <p>
                        Rs.  {amountFormat(7689600)}
                      </p>
                    </div>
                    <div className="item-detail">
                      <label>Total downpayment</label>
                      <p>
                        Rs.  {amountFormat(76896)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line" />
              <div className="flex-between approve-sec">
                <h3>What is your credit risk assessment decision?</h3>
                <div className="report-btns">
                  <button className="approve">Approve</button>
                  <button className="reject">Reject</button>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Collapse className="documents">
        <Panel
          showArrow={false}
          header={
            <div className="header">
              <div className="head-details">
                <h3>Business Existing Debts</h3>
              </div>
            </div>
          }
        >
          <div className="content mt2">
            <div className="account-info">
              <div className="account">
                <h2>Aditya Birla Finance</h2>
                <p>12-month repayment history</p>
                <div>
                  <div className="item paid">
                    <label>Paid:</label>
                    <span>Rs. 24,000</span>
                  </div>
                  <div className="item out">
                    <label>Outstanding:</label>
                    <span>Rs. 22,15,000</span>
                  </div>
                  <div className="item stat">
                    <label>Opened:</label>
                    <span>29/10/2021</span>
                  </div>
                </div>
              </div>
              <IncomeChart />
            </div>
            <div className="account-info mt3">
              <div className="account">
                <h2>Credit Card</h2>
                <p>12-month repayment history</p>
                <div>
                  <div className="item paid">
                    <label>Balance:</label>
                    <span>Rs. 24,000</span>
                  </div>
                  <div className="item out">
                    <label>Limit:</label>
                    <span>Rs. 22,15,000</span>
                  </div>
                  <div className="item stat">
                    <label>Opened:</label>
                    <span>29/10/2021</span>
                  </div>
                </div>
              </div>
              <IncomeChart showAmt={true} />
            </div>
            <div className="line" />
            <div>
              <h3>Credit History</h3>
              <div className="credit-history">
                <p className="paid ext">Number of external accounts: 1</p>
                <div className="active-list">
                  <p>
                    <span>Bank of America</span>
                    <span className="risk_status">Active</span>
                    <span>
                      5.3 years <img src={calendar} width={15} />
                    </span>
                  </p>
                  <p>
                    <span>Chase Bank</span>
                    <span className="risk_status closed">Closed</span>
                    <span>
                      5.3 years <img src={calendar} width={15} />
                    </span>
                  </p>
                  <p>
                    <span>Wells Fargo</span>
                    <span className="risk_status closed">Closed</span>
                    <span>
                      5.3 years <img src={calendar} width={15} />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Collapse className="documents">
        <Panel
          showArrow={false}
          header={
            <div className="header">
              <div className="head-details">
                <h3>Personal Score:</h3>
                <div className="risk-score">
                  <p className="d-flex align-center">
                    739 <span className="ml1 risk_status">Good</span>
                  </p>
                </div>
                <p>National average: 700</p>
              </div>
              <div className="score-check">
                <div className="progress"></div>
                <div className="progress"></div>
                <div className="progress"></div>
                <div className="progress"></div>
                <div className="progress"></div>
              </div>
            </div>
          }
        >
          <div className="content">
            <div className="income-expenditure">
              <h3>Income/Expenditure</h3>
              <div className="account-info mt2">
                <div className="account">
                  <h2>Salary</h2>
                  <p>Monthly Salary ₹40,000.00/month</p>
                  <p>Average spends ₹ 26,500.00/month</p>
                  <div className="mt1">
                    <div className="item">
                      <label>Job Role:</label>
                      <span>Director</span>
                    </div>
                    <div className="item stat">
                      <label>Since:</label>
                      <span>29/10/2021</span>
                    </div>
                  </div>
                </div>
                <ExpenditureChart />
              </div>
            </div>
            <div className="line" />
            <div className="personal-debts">
              <h3>Personal Debts</h3>
              <div className="account-info mt2">
                <div className="account">
                  <h2>HDFC Bank - Car Loan</h2>
                  <p>6-month repayment history</p>
                  <div>
                    <div className="item paid">
                      <label>Paid:</label>
                      <span>Rs. 24,000</span>
                    </div>
                    <div className="item out">
                      <label>Outstanding:</label>
                      <span>Rs. 22,15,000</span>
                    </div>
                    <div className="item stat">
                      <label>Opened:</label>
                      <span>29/10/2021</span>
                    </div>
                  </div>
                </div>
                <IncomeChart />
              </div>
            </div>
            <div className="line" />
            <div className="debts-income-ratio">
              <h3>Debt to Income Ratio</h3>
              <div className="mt2 dti-ratio">
                <div className="dti-score">
                  <div className="risk-score">
                    <h3>DTI Score:</h3>
                    <p className="d-flex align-center">
                      25% <span className="ml1 risk_status">Good</span>
                    </p>
                    <div className="account">
                      <p>National average: 40</p>
                    </div>
                  </div>
                  <div className="account">
                    <p>Monthly Salary ₹40,000.00/month</p>
                    <p>Current EMIs ₹ 26,500.00/month</p>
                    <p>Head Room ₹ 6,500.00/month</p>
                  </div>
                </div>
                <DTIChart />
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default CreditAnalysis;
