import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import { Login, Logout } from "./auth";

export function login(req) {
  return sagaFunctions(Login, "post", apis.staffLogin, req.payload, {
    "Content-Type": "application/json; charset=utf-8",
  })();
}

export function logout() {
  return sagaFunctions(Logout, "post", apis.staffLogout, null, {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: localStorage.getItem("token"),
  })();
}

export function* loginWatcher() {
  yield takeLatest(Login.REQUEST, login);
  yield takeLatest(Logout.REQUEST, logout);
}
