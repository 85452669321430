import { Button, Input, Steps, message } from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import NoCommentsComponent from "../Common/NoComentsComponent";
import Comment from "./icons/message.svg";

const Comments = ({
  comments = [
    { body: "Collected Rental agreement" },
    {
      body: "Uploaded documents are not clear; ask agent to Recollect all docs",
    },
    {
      body: "Can the promoter submit any collateral security against the loan, Profile seems to be okay",
    },
    {
      body: "Co-Ordinate with.the credit risk team for faster approval, No blockages",
    },
    { body: "Loan amount requested is 95 lakhs,\nCan be increased" },
  ],
}) => {
  
  const [input, setInput] = useState("");
  const inputComment = useRef(null);
  const handleAddComment = (event) => {
    if (input.trim()) {
      setInput("");
    } else {
      message.error(
        <span className="messageText">Comments are required</span>
      );
    }
  };
  return (
    <div className="loan-sec p2">
      <div className="comment-sec">
        <div className="addComment mb1" style={{ display: "flex" }}>
          <Input
            placeholder={"Enter Comments"}
            ref={inputComment}
            value={input}
            onInput={(e) => setInput(e.target.value.trimStart())}
          />
          <Button
            key="1"
            className="formButton ml1"
            onClick={() => handleAddComment()}
          >
            Send
          </Button>
        </div>

        {comments?.length ? (
          <>
            <div className="commentsSection">
              <Steps
                direction="vertical"
                size="small"
                current={comments.length + 1}
              >
                {comments &&
                  comments.map(({ body, created_at, id, name }) => (
                    <Steps.Step
                      key={id}
                      title={body}
                      description={`on ${moment(created_at).format(
                        "DD MMM YYYY, HH:mm:ss a"
                      )} | Commented by: ${name ?? "Siva"}`}
                      icon={<img src={Comment} alt="history" width={15} />}
                    />
                  ))}
              </Steps>
            </div>
          </>
        ) : (
          <NoCommentsComponent />
        )}
      </div>
    </div>
  );
};

export default Comments;
