import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/auth";
import close from "../../assets/icons/close.png";
import CAMSummary from "./CAMSummary";
import CaseManagement from "./CaseManagement";
import Inspection from "./Inspection";
import calender from "./icons/calendar.png";
import { useHistory } from "react-router-dom";

const LoanDetails = () => {
  const history = useHistory();
  const [tab, setTab] = useState("a");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Loans",
        description: "",
      })
    );
  }, []);
  const items = [
    {
      key: "a",
      label: "Case Management",
    },
    {
      key: "b",
      label: "CAM Summary",
    },
    {
      key: "c",
      label: "Inspection & Checks",
    },
  ];
  return (
    <div className="loan-details">
      <div className="report-head flex-between">
        <div>
          <div className="title-sec">
            <h3>Working Capital: UNIWC-324</h3>
            <span className="status status_pending">Processing</span>
          </div>
          <p className="sub">
            <img src={calender} width={15} />
            <span>Created: 20 Mar, 2023 at 10:10</span>
          </p>
        </div>
        <img src={close} width={20} className="close" onClick={()=>{
          history.push("/admin/loans")
        }} />
      </div>
      <div>
        <div className="tabs-sec">
          <ul>
            {items.map(
              (ele) =>
                ele?.label && (
                  <li
                    onClick={() => setTab(ele.key?.toLowerCase())}
                    className={`${
                      tab.toLowerCase() === ele.key?.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                  >
                    {ele.label}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {tab === "a" && <CaseManagement />}
      {tab === "b" && <CAMSummary />}
      {tab === "c" && <Inspection />}
    </div>
  );
};

export default LoanDetails;
