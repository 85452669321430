import React, { useState } from "react";
import CreditAnalysis from "./CaseManagementComponents/CreditAnalysis";
import Documents from "./CaseManagementComponents/Documents";
import Overview from "./CaseManagementComponents/Overview";
import Profile from "./CaseManagementComponents/Profile";
import Tasks from "./CaseManagementComponents/Tasks";
import Comments from "./Comments";
import Logs from "./Logs";
const CaseManagement = () => {
  const [tab1, setTab1] = useState("1");
  const items1 = [
    {
      key: "1",
      label: "Overview",
    },
    {
      key: "2",
      label: "Profile",
    },
    {
      key: "3",
      label: "Documents",
    },
    {
      key: "4",
      label: "Credit Analysis",
    },
    {
      key: "5",
      label: "Tasks",
    },
    {
      key: "6",
      label: "Comments",
    },
    {
      key: "7",
      label: "Event Logs",
    },
  ];

  return (
    <>
      <div className="sub-tabs">
        <div className="tabs-sec">
          <ul>
            {items1.map(
              (ele) =>
                ele?.label && (
                  <li
                    onClick={() => setTab1(ele.key?.toLowerCase())}
                    className={`${
                      tab1.toLowerCase() === ele.key?.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                  >
                    {ele.label}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {tab1 === "1" && <Overview />}
      {tab1 === "2" && <Profile />}
      {tab1 === "3" && (
        <>
          <Documents title={"Business PAN"} subTitle={""} />
          <Documents
            title={"Business KYC"}
            subTitle={"GST Certificate, COI, MOA, AOA, Shareholders list"}
          />
          <Documents
            title={"Bank Statement"}
            subTitle={"Last one year bank statement"}
          />
          <Documents
            title={"Financial Documents"}
            subTitle={"ITR/Financials/GST Returns"}
          />
          <Documents
            title={"Other Documents"}
            subTitle={"Collateral documents, Agreemensts"}
          />
        </>
      )}
      {tab1 === "4" && <CreditAnalysis />}
      {tab1 === "5" && <Tasks />}
      {tab1 === "6" && <Comments />}
      {tab1 === "7" && <Logs />}
    </>
  );
};

export default CaseManagement;
