import {
  // Menu, 
  Table
} from "antd";
import React, { useCallback } from "react";
import { withRouter } from "react-router";
import SearchInput from "../SearchInput";
import ShowMoreLess from "./showMore";
import "./table.css";

// const menu = (
//   <Menu>
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#send_invitation">
//         Send Invitation
//       </a>
//     </Menu.Item>
//     <hr />
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#auto_signup">
//         Auto Sign Up
//       </a>
//     </Menu.Item>
//     <hr />
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#generate_password">
//         Generate Password
//       </a>
//     </Menu.Item>
//   </Menu>
// );

const TableWrap = ({
  columns,
  data,
  pagination,
  handleSearch,
  exportToExcel,
  hideExportButton,
  exportLoading,
  rowClassName,
  loading,
  scroll,
  ...props
}) => {
  const handleButtonFunc = (route) => {
    if (route) {
      props.history.push(route);
    }
  };

  const handleCreatePaginator = useCallback((total, range) => {
    return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
  }, []);

  return (
    <div className="paycraft-table">
      {props.isTopFilter && (
        <div className="top-filter" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="flex-between w100">
            <SearchInput
              placeholder={props.placeholder}
              handleSearch={(val) => {
                if (handleSearch)
                  handleSearch(val);
              }}
            />
            <div className="d-flex gap-1" >
              {props?.headerButtonProps && props.isHeaderButton ? (
                <button
                  className="ant-btn ant-btn-primary pp-main-button"
                  onClick={() => handleButtonFunc(props?.headerButtonProps?.route)}
                >
                  {props?.headerButtonProps?.buttonName}
                </button>
              ) : (
                ""
              )}
              {!hideExportButton && <button className="exportButton" disabled={exportLoading} onClick={exportToExcel}>
                {exportLoading ? "Exporting..." : "Export to Excel"}
              </button>}
              {props?.topFilters?.length > 0 ?
                <ShowMoreLess topFilters={props?.topFilters} /> : null}

            </div>
          </div>
        </div>
      )}
      {props.topFil ? (
        <div className="top-filter" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="flex-between w100">
            <SearchInput
              placeholder={props.placeholder}
              handleSearch={(val) => {
                handleSearch && handleSearch(val);
              }}
            />
            {props?.addBtn && <button
              className="ant-btn ant-btn-primary pp-main-button"
              onClick={() => props.handleButtonFunc()}
            >
              {props.buttonText}
            </button>}
          </div>
        </div>
      ) : (
        ""
      )}
      <Table columns={columns} dataSource={data} pagination={{ ...pagination, showTotal: handleCreatePaginator }} scroll={scroll} loading={loading} rowClassName={rowClassName} />
    </div>
  );
};

export default withRouter(TableWrap);
