import { Steps, Popover } from "antd";
import { default as React } from "react";
import user from "../../../assets/icons/user_photo.png";
import { amountFormat } from "../../../utils";

const Overview = () => {
  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
    >
      {dot}
    </Popover>
  );
  return (
    <>
      <div className="pt2 loan-sec">
        <div className="applicant-details">
          <h3>Loan Application</h3>
          <div className="details">
            <div className="item-detail">
              <label>Monthly EMI Amount</label>
              <b>
                Rs.  {amountFormat(204192)}
              </b>
            </div>
            <div className="item-detail">
              <label>Interest Rate</label>
              <b>15%</b>
            </div>
            <div className="item-detail">
              <label>Loan Amount</label>
              <b>
                Rs.  {amountFormat(2041920)}
              </b>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Vendor Id</label>
              <p>30607300056001</p>
            </div>
            <div className="item-detail">
              <label>Currency Code</label>
              <p>INR</p>
            </div>
            <div className="item-detail">
              <label>Payment Terms</label>
              <p>Net 30</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>GST Treatment</label>
              <p>Registered Business - Regular</p>
            </div>
            <div className="item-detail">
              <label>GSTIN</label>
              <p>Yet to be updated</p>
            </div>
            <div className="item-detail">
              <label>Source of Supply</label>
              <p>Hyderabad</p>
            </div>
          </div>
          <div className="line" />
          <div className="promoter-details">
            <h3>Promoter Details</h3>
            <div className="name-details">
              <div>
                <img
                  src={user}
                  alt="avatar"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <p>
                  <b>Abhishek Verma</b>
                </p>
                <p className="acc-stat">
                  <button>Account active</button>
                  Last synced at 10:45
                </p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>Preffered Language</label>
                <p>English</p>
              </div>
              <div className="item-detail">
                <label>Last Contact</label>
                <p>4 days</p>
              </div>
              <div className="item-detail">
                <label>Customer Since</label>
                <p>3 years</p>
              </div>
            </div>
          </div>
          <div className="line" />
          <div className="sourcing-details">
            <h3>Sourcing Details</h3>
            <div className="details">
              <div className="item-detail">
                <label>Source</label>
                <p>Branch</p>
              </div>
              <div className="item-detail">
                <label>Employee Id</label>
                <p>UN43982</p>
              </div>
              <div className="item-detail">
                <label>Campain Details</label>
                <p>-</p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>Branch Name</label>
                <p>Worli Mumbai</p>
              </div>
              <div className="item-detail">
                <label>Employee Mobile</label>
                <p>+91 8089874322</p>
              </div>
              <div className="item-detail">
                <label>Sourcing Channel</label>
                <p>Direct</p>
              </div>
            </div>
          </div>
        </div>
        <div className="loan-status">
          <h3>Loan Status</h3>
          <Steps
            current={1}
            progressDot={customDot}
            direction={"vertical"}
            items={[
              {
                title: (
                  <span>
                    Case Open<span className="sub">Mar 20, 2024</span>
                  </span>
                ),
                description: "Success",
              },
              {
                title: (
                  <span>
                    Pre-Qualification<span className="sub">Mar 20, 2024</span>
                  </span>
                ),
                description: "Success",
              },
              {
                title: (
                  <span>
                    Application Subbmited
                    <span className="sub">Mar 20, 2024</span>
                  </span>
                ),
                description: "Success",
              },
              {
                title: (
                  <span>
                    Data Review<span className="sub">Mar 20, 2024</span>
                  </span>
                ),
                description: "Pending",
              },
              {
                title: (
                  <span>
                    Credit Risk Review<span className="sub">Mar 20, 2024</span>
                  </span>
                ),
                description: "Pending",
              },
              {
                title: (
                  <span>
                    Credit Received<span className="sub">Mar 20, 2024</span>
                  </span>
                ),
                description: "Pending",
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default Overview;
