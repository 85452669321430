import React, { useEffect } from "react";
import PageHeader from "../PageHeader";
import CardImportsList from "./CardImportsList";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/auth/auth";

const CardImports = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(headerTitles({
      title: "Card Imports"
    }))
  }, [])
  return (
    <>

      <CardImportsList />
    </>
  );
};

export default CardImports;
