export const apis = {
  login: `/v1/admin/0/employee/login`,
  reports: `/api/reports/`,
  categories: `/api/admin/categories/`,
  categoriesStatus: `/api/admin/categories/change_status/`,
  deleteCategories: `/api/admin/categories/delete/`,
  deleteClient: `/v1/admin/0/superAdmin/deleteClient`,
  employees: `/api/admin/employees/`,
  companies: `/api/admin/companies/`,
  clientList: "/v1/admin/0/superAdmin/getAllClientDetails",
  leads: "v1/admin/0/superAdmin/getAllLeadsDetails",
  staffLogin: "/v1/admin/0/staff/login",
  staffCompanies: "v1/admin/0/superAdmin/createClient",
  staffUpdateCompanies: "/v1/admin/0/superAdmin/updateClient",
  staffCompanyDetails: "/v1/admin/0/superAdmin/getClientDetailsByID",
  staffEnableCompany: "/v1/admin/0/superAdmin/enableClient",
  staffUserList: "/v1/admin/0/superAdmin/getAllUserDetails",
  staffUserExportList: "/api/staff/users/export_user_list_csv",
  clientExportList: "/api/staff/companies/export_company_list_csv",
  staffCards: "v1/admin/0/superAdmin/getAllCardsDetails",
  staffCardImports: "/api/staff/cards/imports",
  staffCardDownloadFile: "/api/staff/cards/download_file",
  staffLogout: "/v1/admin/0/staff/logout",
  staffUser: "/v1/admin/0/superAdmin/getAllStaffDetails",
  staffUserGet: "/v1/admin/0/superAdmin/getStaffDetailsByID",
  createstaffUser: "/v1/admin/0/superAdmin/createStaffUsers",
  companyDetails: "/v1/admin/0/superAdmin/getCompanyDetails",
  staff_users_delete: "/v1/admin/0/superAdmin/deleteStaffUserDetails",
  staff_users_update: "/v1/admin/0/superAdmin/updateStaffUserDetails",
  base_currencies: `/v1/admin/0/superAdmin/getAllCurrencies`,
  getOnboardPreview: "/v1/admin/0/kyc/getCompanyKycDetails",
  getAllCountriesApi: "/v1/admin/0/kyc/getKycCountries",
  getAllTimeZones: "/v1/admin/0/timeZones/getAllTimeZones",
  staffPermissions: `/v1/admin/0/superAdmin/fetchPermissions`,
  resetPassword: "/v1/admin/0/employee/forgotPassword",
  card_vendors: "v1/admin/0/superAdmin/getAllCardVendors",
  link_card_vendor: "/api/staff/card_vendors/link_card_vendor",
  unlink_card_vendor: "/api/staff/card_vendors/unlink_card_vendor",
  delete_vendor: "/api/staff/card_vendors/delete",
  getIfscDetails: "https://payapi.forgocards.com/v1/ems/0/bank/getIfscDetails",

  //documentsApi
  createDocument: "/v1/document/0/upload",
  updateDocument: "/v1/document/0/attachments",
  deleteDocument: "/v1/document/0/attachments",
  // Dashboard apis
  getDashboardSummary: "v1/admin/0/dashboard/getDashboardSummary",
  getTranscationStats: "v1/admin/0/dashboard/getTranscationStats",
  getTopClients: "v1/admin/0/dashboard/getTopClients",

  //CardVendor apis
  createCardVendor: "/v1/admin/0/superAdmin/createCardVendor",
  onboardClientApproval: "/v1/admin/0/kyc/approveKycDetails",
  onboardClientReject: "/v1/admin/0/kyc/rejectKycDetails",
};
