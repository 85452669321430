import { Collapse } from "antd";
import React, { useState } from "react";
import down from "../icons/caret-down.svg";

const Panel = Collapse.Panel;
const CamInputs = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <Collapse
      className="documents"
      onChange={(e) => {
        setCollapseOpen((b) => !b);
      }}
    >
      <Panel
        showArrow={false}
        header={
          <div className="header">
            <div className="head-details">
              <h3>
                Questionnaire
                <span className="dim-text">(Inputs on the case)</span>
              </h3>
            </div>
            <div className={collapseOpen ? "rotate smooth" : "smooth"}>
              <img src={down} width={10} />
            </div>
          </div>
        }
      >
        <div className="content">
          <div className="questionaire-wrap">
            <p>Mention about the nature of business and revenue growth</p>
            <textarea rows={5} placeholder="Enter your comments" />
          </div>
          <div className="questionaire-wrap">
            <p>Enter the risk associated for client business</p>
            <textarea rows={5} placeholder="Enter your comments" />
          </div>
          <div className="questionaire-wrap">
            <p>Mention any strength of the promoters</p>
            <textarea rows={5} placeholder="Enter your comments" />
          </div>
          <div className="questionaire-wrap">
            <p>Mention business strength, current assets and team</p>
            <textarea rows={5} placeholder="Enter your comments" />
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default CamInputs;
