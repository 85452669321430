import { Col, Dropdown, Menu, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Logout, logout } from "../../actions/auth";
import "./header.css";
import PageHeader from "../PageHeader";
import DOWN from "../../assets/icons/down.svg"


const Header = () => {
  const dispatch = useDispatch();
  const log_out = useSelector(state => state?.auth?.logout);
  const page_headers = useSelector((state) => state?.auth?.localVariables?.titles);

  const history = useHistory()

  useEffect(() => {
    if (log_out?.success) {
      dispatch({
        type: Logout.RESET
      })
      localStorage.clear();
      history.push("/admin/login");
    }
    // eslint-disable-next-line
  }, [log_out])

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Logout</Menu.Item>
    </Menu>
  );

  function handleMenuClick(e) {
    switch (e.key) {
      case "1": {
        dispatch(logout());
        break;
      }
      default:
    }
  }

  return (
    <div className="header-div mb1">
      <Row className="w100">
        <Col span={16} className="header-title ">
          <PageHeader title={page_headers?.title} description={page_headers?.description} />
        </Col>
        <Col span={8} className="header-right d-flex justify-content-end">
          <div className="user-drop" style={{ width: "fit-content" }}>
            <Dropdown
              overlay={menu}
              placement="bottomLeft"
            >
              <div className="d-flex align-center cursor">
                <div className="circle">
                  <span className="first-letter">
                    {"FS"}
                  </span>
                </div>
                <span className="user-name"> Forgo Super Admin
                </span>
                <img src={DOWN} alt="" className="mlHalf down" />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row >
    </div >
  );
};

export default Header;
