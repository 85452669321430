import React, { useEffect, useState } from 'react'
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
// import preview_icon from "../../assets/icons/preview_icon.svg";
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries, onboardPreview } from '../../actions/clients/clients';
import { getFileExtension } from '../../utils';
import png_icon from "../../assets/icons/pdf_file_icon.png"
const OnboardPreview = ({ company_id = {} }) => {
    const dispatch = useDispatch()
    const [isPreviewOpen, setIsPreviewOpen] = useState({
        open: false,
        url: ""
    })
    const onboardPreviewResponse = useSelector((state) => state?.client?.onboardPreview?.data)
    const allCountries = useSelector((state) => state?.client?.getAllCountries?.data?.data?.map((item, index) => ({
        value: item?.country_iso_code,
        label: item?.country_name
    })))
    useEffect(() => {
        if (company_id?.id) {
            dispatch(onboardPreview({ company_id: company_id?.id, params: { preview: true, country: company_id?.country } }))
            dispatch(getAllCountries());
        }
    }, [company_id?.id])
    return (
        <>
            <Modal
                visible={isPreviewOpen?.open}
                onCancel={() => {
                    setIsPreviewOpen({
                        open: false,
                        url: ""
                    })
                }}
                footer={null}
                width="80%"
                centered
                className='preview_section_modal'
            >
                {
                    isPreviewOpen?.fileType == "pdf" ? <div style={{ width: "100%", height: "100vh" }}>
                        <iframe src={isPreviewOpen?.url} allow="fullscreen" style={{ width: "100%", height: "100%", margin: "auto" }} title="description"></iframe>
                    </div> :
                        <img
                            src={isPreviewOpen?.url}
                            alt="preview image"
                            style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
                        />
                }
            </Modal>
            <div className='preview_section'>
                <div className='preview_container w100'>
                    {Array.isArray(onboardPreviewResponse?.data) &&
                        onboardPreviewResponse?.data?.map((fieldsElement, fieldsIndex) => (
                            <div className='preview_card mt2' key={fieldsIndex}>
                                <div className='header_section d-flex'>
                                    <h4 className='mb0 mr1'>{fieldsElement?.section_title}</h4>
                                </div>
                                <div className='header_line'></div>
                                <div className='preview_data'>
                                    {
                                        fieldsElement?.fields_list?.map((listElement, listIndex) => {

                                            if (listElement?.data_type == "date") {
                                                return (
                                                    <div className='key_value_div' key={listIndex}>
                                                        <div className='d-flex flex-col' >
                                                            <span className='title'>{listElement?.entity_column_label}</span>
                                                            <span className='value'>{listElement?.value ? listElement?.entity_column == "year_of_incorporation" ? moment(listElement?.value)?.format("YYYY") : moment(listElement?.value)?.format("DD-MM-YYYY") : "-"}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (listElement?.data_type == "dropdown") {
                                                let dropdownValue;
                                                if (listElement?.dropdown_fields) {
                                                    dropdownValue = listElement?.dropdown_fields?.find(ele => ele?.value == listElement?.value)
                                                } else {
                                                    dropdownValue = allCountries?.find(ele => ele?.value == listElement?.value)
                                                }
                                                return (
                                                    <div className='key_value_div' key={listIndex}>
                                                        <div className='d-flex flex-col'>
                                                            <span className='title'>{listElement?.entity_column_label}</span>
                                                            <span className='value'>{listElement?.value ? dropdownValue?.label : "-"}</span>
                                                        </div>
                                                    </div>
                                                )
                                            } else if (listElement?.data_type == "document") {
                                                return (
                                                    <div className={`prevew_document_section ${listElement?.data_type == "document" && listIndex > 0 ? "mt1" : ""}`} key={listIndex}>
                                                        <h4 className='file_name'>{listElement?.entity_column_label}</h4>
                                                        <div className='prevew_document_container flex-between mb1'>
                                                            <div className='left_section d-flex'>
                                                                <div className='image_section mr1'>
                                                                    {(() => {
                                                                        if (["pdf"]?.includes(getFileExtension(listElement?.file_properties?.filename)?.toLowerCase())) {
                                                                            return <img src={png_icon} className='w100 h100' />;
                                                                        } else {
                                                                            return <img src={listElement?.value} className='w100 h100' />;
                                                                        }
                                                                    })()}
                                                                </div>
                                                                <div className='image_name justify-center flex-col'>
                                                                    <h4 className='file_name'>{listElement?.file_properties?.filename}</h4>
                                                                </div>
                                                            </div>
                                                            <div className='right_section'>
                                                                <div className='active_section d-flex'>
                                                                    <div className='preview_section' onClick={() => {
                                                                        setIsPreviewOpen(() => ({ open: true, url: listElement?.value, fileType: getFileExtension(listElement?.file_properties?.filename) }))
                                                                    }}>
                                                                        <EyeOutlined className='preview_img' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className='key_value_div' key={listIndex}>
                                                        <div className='d-flex flex-col'>
                                                            <span className='title'>{listElement?.entity_column_label}</span>
                                                            <span className='value'>{listElement?.value ? listElement?.value : "-"}</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default OnboardPreview;