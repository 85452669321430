import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaDocumentFunctions, sagaFunctions } from "../../utils";
import {
  AddCompany,
  AddStaffCompany,
  BaseCurrencies,
  CompanyDetails,
  UploadDocument,
  UpdateDocument,
  DeleteDocument,
  GetDocument,
  CompanyEnable,
  CompanyList,
  Leads,
  StaffCompanyDetails,
  StaffCompanyList,
  OnboardPreview,
  GetAllCountries,
  GetAllTimeZones,
} from "./clients";

function addCompany(req) {
  let formdata = new FormData();
  Object.keys(req.payload).forEach((ele) => {
    formdata.append(ele, req.payload[ele]);
  });
  return sagaFunctions(AddCompany, "post", apis.companies, formdata, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function getCompanyList(req) {
  return sagaFunctions(CompanyList, "post", apis.clientList, req.payload)();
}

function getCompanyDetails(req) {
  return sagaFunctions(CompanyDetails, "post", apis.companyDetails, req.payload)();

}

//Documents
function uploadDocument(req) {
  return sagaDocumentFunctions(UploadDocument, "post", apis.createDocument, req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function updateDocument(req) {
  return sagaDocumentFunctions(UpdateDocument, "post", apis.updateDocument + `?record_id=${req.others}`, req.payload,
    {
      "Content-Type": "multipart/formdata",
    }
  )();
}

function deleteDocument(req) {
  return sagaDocumentFunctions(DeleteDocument, "delete", apis.deleteDocument, req.payload,
  )();
}

function getDocument(req) {
  return sagaDocumentFunctions(GetDocument, "delete", apis.getDocument, req.payload,
  )();
}


function getLeads(req) {
  return sagaFunctions(Leads, "post", apis.leads, req.payload)();
}

function enalbeCompany(req) {
  return sagaFunctions(
    CompanyEnable,
    "post",
    apis.staffEnableCompany,
    // +
    //   `?company_id=${req.payload.cmpId}&enabled=${req.payload.enabled}`,
    {
      company_id: req.payload.cmpId,
      enabled: req.payload.enabled
    }
  )();
}

function getStaffCompany(req) {
  return sagaFunctions(StaffCompanyDetails, "get", apis.staffCompanyDetails,
    // `?id=${req.payload.id}` 
    {
      id: req.payload,
    }
  )();
}

function addStaffCompany(req) {
  // let formdata = new FormData();
  // Object.keys(req.payload).forEach((ele) => {
  //   formdata.append(ele, req.payload[ele]);
  // });
  return sagaFunctions(
    AddStaffCompany,
    "post",
    req.payload.id ? apis.staffUpdateCompanies :
      apis.staffCompanies,
    req.payload
  )();
}

function getStaffCompanyList(req) {
  return sagaFunctions(
    StaffCompanyList,
    "post",
    apis.staffCompanies,
    req.payload
  )();
}

function getBaseCurrencies(req) {
  return sagaFunctions(
    BaseCurrencies,
    "get",
    apis.base_currencies,
    req.payload
  )();
}
function onboardPreview(req) {
  return sagaFunctions(
    OnboardPreview, "post", apis.getOnboardPreview, req.payload
  )();
}
function getAllCountries(req) {
  return sagaFunctions(
    GetAllCountries, "get", apis.getAllCountriesApi, req.payload
  )();
}

function getAllTzs(req) {
  return sagaFunctions(
    GetAllTimeZones, "get", apis.getAllTimeZones, req.payload
  )();
}

export function* companyWatcher() {
  yield takeLatest(BaseCurrencies.REQUEST, getBaseCurrencies);
  yield takeLatest(CompanyList.REQUEST, getCompanyList);
  yield takeLatest(CompanyDetails.REQUEST, getCompanyDetails);
  yield takeLatest(UploadDocument.REQUEST, uploadDocument)
  yield takeLatest(UpdateDocument.REQUEST, updateDocument)
  yield takeLatest(DeleteDocument.REQUEST, deleteDocument)
  yield takeLatest(GetDocument.REQUEST, getDocument)
  yield takeLatest(Leads.REQUEST, getLeads);
  yield takeLatest(AddCompany.REQUEST, addCompany);
  yield takeLatest(AddStaffCompany.REQUEST, addStaffCompany);
  yield takeLatest(StaffCompanyList.REQUEST, getStaffCompanyList);
  yield takeLatest(StaffCompanyDetails.REQUEST, getStaffCompany);
  yield takeLatest(CompanyEnable.REQUEST, enalbeCompany);
  yield takeLatest(OnboardPreview.REQUEST, onboardPreview);
  yield takeLatest(GetAllCountries.REQUEST, getAllCountries);
  yield takeLatest(GetAllTimeZones.REQUEST, getAllTzs);
}
