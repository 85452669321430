import React, { useEffect } from "react";
var Highcharts = require("highcharts");

const DTIChart = () => {
  useEffect(() => {
    Highcharts.chart("container1", {
      colors: ["#EC6666", "#79D2DE", "#147AD6"],
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Breakdown",
          colorByPoint: true,
          innerSize: "60%",
          data: [
            {
              name: "Expenses",
              y: 54,
            },
            {
              name: "Existing EMIs",
              y: 30,
            },
            {
              name: "Probable Savings",
              y: 26,
            },
          ],
        },
      ],
    });
  }, []);

  return (
    <div
      className="dti-chart"
      id="container1"
      style={{ width: 500, height: 250 }}
    ></div>
  );
};

export default DTIChart;
