import React from "react";
import no_data from "../../assets/icons/no_comments.png";

const NoCommentsComponent = () => {
  return (
    <div className="d-flex-center flex-col">
      <img
        src={no_data}
        alt=""
        style={{
          width: 120,
        }}
      />
      <p className="text-center mt1 no-records">No comments found</p>
    </div>
  );
};

export default NoCommentsComponent;
