import { Steps } from "antd";
import moment from "moment";
import React from "react";
import NoCommentsComponent from "../Common/NoComentsComponent";
import Comment from "./icons/user_blue.svg";

const Logs = ({
  logs = [
    { body: "Collected Rental agreement" },
    {
      body: "Uploaded documents are not clear; ask agent to Recollect all docs",
    },
    {
      body: "Can the promoter submit any collateral security against the loan, Profile seems to be okay",
    },
    {
      body: "Co-Ordinate with.the credit risk team for faster approval, No blockages",
    },
    { body: "Loan amount requested is 95 lakhs,\nCan be increased" },
  ],
}) => {
 
  return (
    <div className="loan-sec p2">
      <div className="comment-sec">
        {logs?.length ? (
          <>
            <div className="commentsSection">
              <Steps
                direction="vertical"
                size="small"
                current={logs.length + 1}
              >
                {logs &&
                  logs.map(({ body, created_at, id, name }) => (
                    <Steps.Step
                      key={id}
                      title={body}
                      description={`on ${moment(created_at).format(
                        "DD MMM YYYY, HH:mm:ss a"
                      )} | Commented by: ${name ?? "Siva"}`}
                      icon={<img src={Comment} alt="history" width={10} />}
                    />
                  ))}
              </Steps>
            </div>
          </>
        ) : (
          <NoCommentsComponent />
        )}
      </div>
    </div>
  );
};

export default Logs;
