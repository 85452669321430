import { Col, Row } from "antd";
import React from "react";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Common/TableComp/TableHeader";

const Tasks = () => {
  const data = [
    {
      key: "1",
      createdBy: "Chandra Sekhar",
      taskDescription: "Negotiate on the Interest Rate",
      createdOn: "9 July '22",
      assignedTo: "James Peter",
      remarks: "Customer disagree",
      status: "In process",
    },
    {
      key: "2",
      createdBy: "Lokesh Reddy",
      taskDescription: "Do onsite verification",
      createdOn: "19 July '22",
      assignedTo: "Nornan Prem",
      remarks: "",
      status: "Completed",
    },
    {
      key: "3",
      createdBy: "Rajsekhar Reddy",
      taskDescription: "Followup for ITR documents",
      createdOn: "22 June '22",
      assignedTo: "Raj Gopal",
      remarks: "",
      status: "Completed",
    },
  ];

  const columns = [
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Task Description",
      dataIndex: "taskDescription",
      key: "taskDescription",
    },
    {
      title: "Created on",
      dataIndex: "createdOn",
      key: "createdOn",
    },
    {
      title: "Assigned to",
      dataIndex: "assignedTo",
      key: "assignedTo",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div>
          {status.includes("In process") && (
            <span style={{ color: "orange" }}>In process</span>
          )}
          {status.includes("Completed") && (
            <span style={{ color: "green" }}>Completed</span>
          )}
        </div>
      ),
    },
  ];
  return (
    <Row wrap={false} className="table-wrapper m2">
      <Col flex="auto" className="table-wrapper-col">
        <div className="flex-grow">
          <TableHeader noDD={true} />
          <div style={{ paddingTop: "15px" }}>
            <TableComponent columns={columns} hasCheckbox={false} data={data} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Tasks;
