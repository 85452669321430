import React, { useEffect } from "react";
import CardDetailsList from "../Users/EditUser/CardDetailsList";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/auth";

const CardsDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(headerTitles({
      title: "Cards"
    }))
  }, [])
  return (
    <>
      <CardDetailsList />
    </>
  );
};

export default CardsDashboard;
