import { ReducerFunctions } from "../../utils";
import { HeaderTitles, Login, Logout } from "./auth";

export const login_reducer = ReducerFunctions(Login, {});
export const logout_reducer = ReducerFunctions(Logout, {});
export const local_varaibles = (state = { is_admin: "", policyViolations: [], policyModal: false, language: "en" }, action) => {
  switch (action.type) {
    case HeaderTitles:
      return {
        ...state,
        titles: action.payload
      }
    default:
      return state;
  }
};
