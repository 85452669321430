import React from "react";

const IncomeChart = ({ showAmt }) => {
  const data = [
    { month: "Jan", value: 1122.83 },
    { month: "Feb", value: 1122.83 },
    { month: "Mar", value: 1122.83 },
    { month: "Apr", value: 1122.83 },
    { month: "May", value: 1122.83 },
    { month: "Jun", value: 1122.83 },
    { month: "Jul", value: 1122.83 },
    { month: "Aug", value: 1122.83 },
    { month: "Sep", value: 1122.83 },
    { month: "Oct", value: 1122.83 },
    { month: "Nov", value: 1122.83 },
    { month: "Dec", value: 1122.83 },
  ];

  return (
    <div className="income-chart" style={{ width: "100%" }}>
      <div className="emi">₹1,22,830.00/month</div>
      <div className="progress">
        <div
          className="active-progress"
          style={{
            width: "70%",
          }}
        ></div>
      </div>
      <div className="flex-between">
        {data?.map((ele) => (
          <div className="axis">
            <span>{ele.month}</span>
            <span
              className="dot"
              style={{
                background: "#59B67A",
                marginBottom: 5,
              }}
            ></span>
            {showAmt ? (
              <span
                style={{
                  color: "#59B67A",
                  fontSize: 10,
                }}
              >
                ₹{ele.value}
              </span>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncomeChart;
