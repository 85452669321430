import React, { useState } from "react";
import RCUCheck from "./InspectionsAndChecks/RCUCheck";
const Inspection = () => {
  const [tab1, setTab1] = useState("1");
  const items1 = [
    {
      key: "1",
      label: "RCU Check",
    },
    {
      key: "2",
      label: "Field Inspection",
    },
    {
      key: "3",
      label: "Third Party Verfication",
    },
    {
      key: "4",
      label: "Deviations",
    },
    {
      key: "5",
      label: "Personal Discussions",
    },
  ];

  return (
    <>
      <div className="sub-tabs">
        <div className="tabs-sec">
          <ul>
            {items1.map(
              (ele) =>
                ele?.label && (
                  <li
                    onClick={() => setTab1(ele.key?.toLowerCase())}
                    className={`${
                      tab1.toLowerCase() === ele.key?.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                  >
                    {ele.label}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {tab1 === "1" && <RCUCheck />}
    </>
  );
};

export default Inspection;
