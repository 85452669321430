import React from "react";
import "./auth.css";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import {
  Switch,
  Route,
} from "react-router-dom";

const Login = () => {
  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-left">
            <div className="mainLoginDiv">
            <Switch>
              <Route exact path="/admin/login">
                <LoginForm />
              </Route>
              <Route exact path="/admin/resetpassword/">
                <ResetPassword />
              </Route>
            </Switch>
            </div>
        </div>
        {/* <div className="w60 login-right">
          <div className="mAuto w80">
            <Title level={3}>Expense Management for Growing Businesses</Title>
            <Text>Get control and visibility over all company expenses</Text>
            <img src={RightImg} alt="right_img" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
