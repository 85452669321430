import { ActionTypesFactory } from "../../utils";

export const AddCompany = ActionTypesFactory("AddCompany", "Company");
export const CompanyList = ActionTypesFactory("Company", "CompanyList");
export const CompanyDetails = ActionTypesFactory("Company", "CompanyDetails")
export const CompanyEnable = ActionTypesFactory("Company", "CompanyEnable");
export const BaseCurrencies = ActionTypesFactory("Company", "BaseCurrencies");

export const AddStaffCompany = ActionTypesFactory("StaffCompany", "Company");
export const Leads = ActionTypesFactory("Company", "Leads");

export const StaffCompanyDetails = ActionTypesFactory(
  "StaffCompany",
  "CompanyDetails"
);

export const UploadDocument = ActionTypesFactory("UploadDocument", "CompanyDetails")

export const UpdateDocument = ActionTypesFactory("UpdateDocument", "CompanyDetails")

export const DeleteDocument = ActionTypesFactory("DeleteDocument", "CompanyDetails")

export const GetDocument = ActionTypesFactory("GetDocument", "CompanyDetails")

export const StaffCompanyList = ActionTypesFactory(
  "StaffCompany",
  "CompanyList"
);
export const OnboardPreview = ActionTypesFactory("OnboardPreview", "OnboardPreview")
export const GetAllCountries = ActionTypesFactory("GetAllCountries", "GetAllCountries")
export const GetAllTimeZones = ActionTypesFactory("GetAllTimeZones", "GetAllTimeZones")
export const addCompany = (payload) => {
  return {
    type: AddCompany.REQUEST,
    payload,
  };
};

export const base_currencies = (payload) => {
  return {
    type: BaseCurrencies.REQUEST,
    payload,
  };
};

export const getLeads = (payload) => {
  return {
    type: Leads.REQUEST,
    payload,
  };
};

export const getCompanyList = (payload) => {
  return {
    type: CompanyList.REQUEST,
    payload,
  };
};

export const getCompanyDetails = (payload) => {
  return {
    type: CompanyDetails.REQUEST,
    payload,
  };
}

export const uploadDocument = (payload) => {
  return {
    type: UploadDocument.REQUEST,
    payload,
  }
}

export const updateDocument = (payload, others) => {
  console.log("clident upload document", { payload: payload, others: others })
  return {
    type: UpdateDocument.REQUEST,
    payload,
    others
  }
}

export const deleteDocument = (payload) => {
  return {
    type: DeleteDocument.REQUEST,
    payload
  }
}

export const getDocument = (payload) => {
  return {
    type: GetDocument.REQUEST,
    payload
  }
}

export const addStaffCompany = (payload) => {
  return {
    type: AddStaffCompany.REQUEST,
    payload,
  };
};


export const getStaffCompanyList = (payload) => {
  return {
    type: StaffCompanyList.REQUEST,
    payload,
  };
};

export const enableStaffCompany = (payload) => {
  return {
    type: CompanyEnable.REQUEST,
    payload,
  };
};

export const getStaffCompanyDetails = (payload) => {
  return {
    type: StaffCompanyDetails.REQUEST,
    payload,
  };
};

export const onboardPreview = (payload) => {
  return {
    type: OnboardPreview.REQUEST,
    payload,
  }
}
export const getAllCountries = (payload) => {
  return {
    type: GetAllCountries.REQUEST,
    payload,
  }
}

export const getAllTimezones = (payload) => {
  return {
    type: GetAllTimeZones.REQUEST,
    payload,
  }
}
