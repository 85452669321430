import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Common/Table/Table";
import { get_card_vendors } from "../../../actions/cards/cards";
import AddCardVendor from "./AddCardVendor";
import { hasAccess } from "../../../utils";
import { headerTitles } from "../../../actions/auth/auth";

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Outlet Id',
    dataIndex: "api_credentials",
    render: (text) => text.outletId,
  },
  {
    title: 'Branch Id',
    dataIndex: "api_credentials",
    render: (text) => text.branchId,
  }, {
    title: 'Tenant Id',
    dataIndex: "api_credentials",
    render: (text) => text.tenantId,
  }, {
    title: 'Channel Id',
    dataIndex: "api_credentials",
    render: (text) => text.channelId,
  },
  {
    title: 'Base Url',
    dataIndex: "api_credentials",
    render: (text) => text.base_url,
  },
  {
    title: 'Subscription key',
    dataIndex: "api_credentials",
    render: (text) => text.ocp_apim_subscription_key,
  },
];


const CardVendors = () => {
  const [addStaffModal, setAddStaffModal] = useState();
  const [filters, setFilters] = React.useState({});
  const data = useSelector(state => state?.cards?.get_card_vendors?.data?.data);
  const cards_total = useSelector(state => state?.cards?.get_card_vendors?.data?.total_count);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_card_vendors())
    dispatch(headerTitles({
      title: "Card Vendors"
    }))
    // eslint-disable-next-line
  }, [])

  const handleSearch = (value) => {
    dispatch(
      get_card_vendors({ params: { page_number: 1 }, search_key: value })
    );
    setFilters({
      ...filters,
      search_key: value,
      params: { page_number: 1 },
    });
  };

  return (
    <>
      <Table
        columns={columns}
        placeholder={"Search By Name"}
        data={data}
        addBtn={hasAccess("card_vendors", "write")}
        topFil={true}
        handleSearch={handleSearch}
        isTopFilters={true}
        buttonText={"Add Card Vendor"}
        pagination={{
          total: cards_total || 0,
          onChange: (page) => {
            dispatch(get_card_vendors({ ...filters, params: { page_number: page } }));
          },
        }}
        handleButtonFunc={() => {
          setAddStaffModal(true)
        }} />
      <Modal
        visible={addStaffModal}
        title="Add Card Vendor"
        className="right-alinged-modal"
        footer={[]}
        // closable={false}
        onCancel={() => {
          setAddStaffModal(false)
        }}
      >
        {addStaffModal && <AddCardVendor onCancel={() => {
          setAddStaffModal(false)
        }} />}
      </Modal>
    </>
  );
};

export default CardVendors;
