import { ActionTypesFactory } from "../../utils";

export const Login = ActionTypesFactory("Login", "Auth");
export const Logout = ActionTypesFactory("Logout", "Auth");
export const HeaderTitles = "HEADER_TITLES";

export const login = (payload) => {
  return {
    type: Login.REQUEST,
    payload,
  };
};

export const logout = () => {
  return {
    type: Logout.REQUEST,
  };
};

export const headerTitles = (payload) => {
  return {
    type: HeaderTitles,
    payload,
  };
};
