import React from "react";

import { amountFormat } from "../../../utils";

const Eligibilty = () => {
  return (
    <div>
      <div className="pt2 loan-sec">
        <div className="applicant-details">
          <h3>Approved Limits</h3>
          <div className="details">
            <div className="item-detail">
              <label>Selected Product</label>
              <p>
                <span className="risk_status">Working Capital</span>
              </p>
            </div>
            <div className="item-detail">
              <label>Tenure</label>
              <p>20 years</p>
            </div>
            <div className="item-detail">
              <label>Business Age</label>
              <p>8 years</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Eligible Amount</label>
              <p> Rs. {amountFormat(50000000)}</p>
            </div>
            <div className="item-detail">
              <label>Requested Amount</label>
              <p> Rs. {amountFormat(9500000)}</p>
            </div>
            <div className="item-detail">
              <label>Business office type</label>
              <p>Owned</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Approved Interest Rate</label>
              <p>10.5%</p>
            </div>
            <div className="item-detail">
              <label>Approved Amount</label>
              <p>Rs. {amountFormat(9500000)}</p>
            </div>
            <div className="item-detail">
              <label>Monthly EMI Amount</label>
              <p>Rs. {amountFormat(204192)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eligibilty;
